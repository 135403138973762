/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60dd6557d841571629949016").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class IndexView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/IndexController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = IndexView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '60dd6557d841572b9f949015'
    htmlEl.dataset['wfSite'] = '60dd6557d841571629949016'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = IndexView.Controller !== IndexView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/rentunicorn.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div data-collapse="medium" data-animation="default" data-duration={400} data-w-id="19a6dfdb-4591-1fd6-8825-9b860763fd81" data-easing="ease" data-easing2="ease" role="banner" className="af-class-navbar w-nav">
              <div className="af-class-nav_wrapper">
                <div className="af-class-nav-container">
                  <a href="/" aria-current="page" className="af-class-brand w-nav-brand w--current"><img src="images/Group-1907.svg" loading="lazy" alt="Unicorn" className="af-class-image-5" /></a>
                  <nav role="navigation" className="af-class-nav-menu w-nav-menu">
                    <a href="landlords" className="af-class-div-block-15 w-inline-block">
                      <div className="af-class-text-block-24">Brokers / Landlords</div>
                    </a>
                    <a href="https://rentunicorn.typeform.com/to/W17RIkva" className="af-class-div-block-15 w-inline-block">
                      <div className="af-class-text-block-24">Become a Scout</div>
                    </a>
                    <a href="https://www.app.rentunicorn.com/" className="af-class-button-3 w-button">Login / Sign Up</a>
                  </nav>
                  <div className="af-class-menu-button w-nav-button">
                    <div className="af-class-icon w-icon-nav-menu" />
                    <div className="af-class-menu-line" />
                    <div className="af-class-menu-line af-class-margin" />
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-over-flow-hidden-wrapper">
              <div className="af-class-hero-section af-class-wf-section">
                <div className="af-class-background-video af-class-home w-embed">
                  <div style={{fontFamily: 'sans-serif', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <video style={{width: '55.45%', height: '100%', objectFit: 'contain'}} autoPlay loop muted playsInline data-wf-ignore="true">
                      <source src="https://unicorn-marketing-static-assets-us-east-1.s3.amazonaws.com/UnicornHeroVideoNoShadow.mov" type="video/mp4" />
                      <source src="https://unicorn-marketing-static-assets-us-east-1.s3.amazonaws.com/UnicornHeroVideoNoShadow.webm" type="video/webm" />
                    </video>
                  </div>
                </div><img src="images/circle.svg" loading="eager" alt className="af-class-image-16" /><img src="images/hero_background_grid.svg" loading="eager" alt className="af-class-grid_background" />
                <div className="af-class-main-container">
                  <div className="af-class-hero-content">
                    <div className="af-class-hero-image af-class-responsiveness"><img src="images/dw.svg" loading="lazy" alt className="af-class-fade-image" />
                      <div className="af-class-background-video af-class-_2 af-class-_7 w-embed">
                        <div style={{fontFamily: 'sans-serif', maxWidth: '100%'}}>
                          <div style={{}}>
                            <video autoPlay loop muted playsInline data-wf-ignore="true" data-object-fit="cover" width={500} height={500}>
                              <source src="https://unicorn-marketing-static-assets-us-east-1.s3.amazonaws.com/UnicornHeroVideoSmall.mov" type="video/mp4" />
                              <source src="https://unicorn-marketing-static-assets-us-east-1.s3.amazonaws.com/UnicornHeroVideo.webm" type="video/webm" />
                            </video>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-hero-description">
                      <h1 className="af-class-h1-hero-heading">Exclusive LA rentals found nowhere else</h1>
                      <div className="af-class-text-block-25">Houses, condos, and apartments for rent.</div>
                      <a href="https://www.app.rentunicorn.com/discover" className="af-class-hero-btn w-button">Start Searching</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-section-2 af-class-white af-class-wf-section">
                <div className="af-class-main-container">
                  <div className="af-class-cards-content">
                    <h2 className="af-class-heading">A magical, video-first apartment listing platform</h2>
                    <div className="af-class-cards-block">
                      <div data-w-id="6083856b-2450-129d-b687-1440248682c7" style={{opacity: 0}} className="af-class-cards"><img src="images/1c.svg" loading="lazy" alt />
                        <div className="af-class-text-block">Plan ahead</div>
                        <div className="af-class-text-block-3">We list homes months in advance so you can search by your move out date</div>
                      </div>
                      <div id="w-node-_56c2d25a-1786-ec24-ab23-77f5f04632ce-9f949015" data-w-id="56c2d25a-1786-ec24-ab23-77f5f04632ce" style={{opacity: 0}} className="af-class-cards"><img src="images/2c.svg" loading="lazy" alt />
                        <div className="af-class-text-block">Find your dream rental</div>
                        <div className="af-class-text-block-3">Search a variety of Unicorn-exclusive listings to find your perfect home</div>
                      </div>
                      <div id="w-node-cee729e2-c0e1-bcd8-f2ac-45e7de25c251-9f949015" data-w-id="cee729e2-c0e1-bcd8-f2ac-45e7de25c251" style={{opacity: 0}} className="af-class-cards"><img src="images/3c.svg" loading="lazy" alt />
                        <div className="af-class-text-block">Read real reviews</div>
                        <div className="af-class-text-block-3">Read hundreds of verified reviews from tenants who have actually lived there</div>
                      </div>
                    </div>
                    <a data-w-id="f7e5d7d2-f55f-698b-e2e0-8ca3744de2cc" style={{opacity: 0}} href="https://www.app.rentunicorn.com/discover" className="af-class-sign-up af-class-_2 w-button">Start Searching</a>
                  </div>
                </div>
              </div>
              <div className="af-class-section-3 af-class-wf-section">
                <div className="af-class-main-container af-class-hid">
                  <div className="af-class-get-stareted-content">
                    <h2 className="af-class-h2">Lock in your next home before you leave your current one</h2>
                    <div className="af-class-text-block-4">Reduce stress by planning ahead</div>
                    <a href="https://www.app.rentunicorn.com/create" className="af-class-get-stared w-button">Get Started</a>
                  </div>
                </div>
                <div className="af-class-div-block-16">
                  <div className="af-class-text-block-26">Discover amazing units <span className="af-class-text-span-5">found only on Unicorn</span></div>
                  <div className="af-class-text-block-27">Hundreds of proprietary listings you won’t find anywhere else</div>
                  <div className="af-class-div-block-17">
                    <a href="https://www.app.rentunicorn.com/" className="af-class-link-block-6 w-inline-block">
                      <div className="af-class-div-block-18"><img src="images/Listing-Photo.png" loading="lazy" sizes="(max-width: 767px) 100vw, (max-width: 991px) 224.0625px, (max-width: 1279px) 23vw, (max-width: 1439px) 296.25px, 305px" srcSet="images/Listing-Photo-p-500.png 500w, images/Listing-Photo-p-800.png 800w, images/Listing-Photo.png 915w" alt className="af-class-image-19" /><img src="images/heart_unfilled.svg" loading="lazy" alt className="af-class-image-20" /></div>
                      <div className="af-class-div-block-20">
                        <div className="af-class-text-block-28">NEW TO YOU</div>
                        <div className="af-class-div-block-19">
                          <div className="af-class-text-block-29">Bungalow • Venice</div>
                          <div className="af-class-text-block-30">$3.4K</div>
                        </div>
                        <div className="af-class-div-block-19">
                          <div className="af-class-text-block-29 af-class-thin">3 Beds &nbsp;3 Baths</div>
                          <div className="af-class-text-block-30 af-class-thuin">Avail. Jul. 12</div>
                        </div>
                      </div>
                    </a>
                    <a href="https://www.app.rentunicorn.com/" className="af-class-link-block-3 w-inline-block">
                      <div className="af-class-div-block-18"><img src="images/Listing-Photo1.png" loading="lazy" sizes="(max-width: 767px) 100vw, (max-width: 991px) 224.0625px, (max-width: 1279px) 23vw, (max-width: 1439px) 296.25px, 305px" srcSet="images/Listing-Photo1-p-500.png 500w, images/Listing-Photo1-p-800.png 800w, images/Listing-Photo1.png 915w" alt className="af-class-image-19" /><img src="images/heart_unfilled.svg" loading="lazy" alt className="af-class-image-20" /></div>
                      <div className="af-class-div-block-20">
                        <div className="af-class-text-block-28">NEW TO YOU</div>
                        <div className="af-class-div-block-19">
                          <div className="af-class-text-block-29">House • Santa Monica</div>
                          <div className="af-class-text-block-30">$2.9K</div>
                        </div>
                        <div className="af-class-div-block-19">
                          <div className="af-class-text-block-29 af-class-thin">1 Beds &nbsp;1.5 Baths</div>
                          <div className="af-class-text-block-30 af-class-thuin">Avail. Mar. 22</div>
                        </div>
                      </div>
                    </a>
                    <a href="https://www.app.rentunicorn.com/" className="af-class-link-block-4 w-inline-block">
                      <div className="af-class-div-block-18"><img src="images/Listing-Photo-2.png" loading="lazy" sizes="(max-width: 767px) 100vw, (max-width: 991px) 224.0625px, (max-width: 1279px) 23vw, (max-width: 1439px) 296.25px, 305px" srcSet="images/Listing-Photo-2-p-500.png 500w, images/Listing-Photo-2-p-800.png 800w, images/Listing-Photo-2.png 915w" alt className="af-class-image-19" /><img src="images/heart_unfilled.svg" loading="lazy" alt className="af-class-image-20" /></div>
                      <div className="af-class-div-block-20">
                        <div className="af-class-text-block-28">NEW TO YOU</div>
                        <div className="af-class-div-block-19">
                          <div className="af-class-text-block-29 af-class-bbig">Apartment • Santa Monica</div>
                          <div className="af-class-text-block-30">$3.2K</div>
                        </div>
                        <div className="af-class-div-block-19">
                          <div className="af-class-text-block-29 af-class-thin">2 Beds &nbsp;2.5 Baths</div>
                          <div className="af-class-text-block-30 af-class-thuin">Avail. Apr. 29</div>
                        </div>
                      </div>
                    </a>
                    <a href="https://www.app.rentunicorn.com/" className="af-class-link-block-5 w-inline-block">
                      <div className="af-class-div-block-18"><img src="images/Listing-Photo-3.png" loading="lazy" sizes="(max-width: 767px) 100vw, (max-width: 991px) 224.0625px, (max-width: 1279px) 23vw, (max-width: 1439px) 296.25px, 305px" srcSet="images/Listing-Photo-3-p-500.png 500w, images/Listing-Photo-3-p-800.png 800w, images/Listing-Photo-3.png 915w" alt className="af-class-image-19" /><img src="images/heart_unfilled.svg" loading="lazy" alt className="af-class-image-20" /></div>
                      <div className="af-class-div-block-20">
                        <div className="af-class-text-block-28">NEW TO YOU</div>
                        <div className="af-class-div-block-19">
                          <div className="af-class-text-block-29">House • Venice</div>
                          <div className="af-class-text-block-30">$4.7K</div>
                        </div>
                        <div className="af-class-div-block-19">
                          <div className="af-class-text-block-29 af-class-thin">4 Beds &nbsp;2.5 Baths</div>
                          <div className="af-class-text-block-30 af-class-thuin">Avail. Jun. 17</div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="af-class-mobile">
                    <div data-delay={4000} data-animation="slide" className="af-class-slider-2 w-slider" data-autoplay="false" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit={0} data-nav-spacing={3} data-duration={500} data-infinite="true">
                      <div className="af-class-mask-2 w-slider-mask">
                        <div className="af-class-slide-6 w-slide">
                          <a href="https://www.app.rentunicorn.com/create" className="af-class-link-block-7 w-inline-block">
                            <div className="af-class-div-block-18"><img src="images/Listing-Photo.png" loading="lazy" sizes="(max-width: 479px) 80vw, (max-width: 767px) 250px, 100vw" srcSet="images/Listing-Photo-p-500.png 500w, images/Listing-Photo-p-800.png 800w, images/Listing-Photo.png 915w" alt className="af-class-image-19" /><img src="images/heart_unfilled.svg" loading="lazy" alt className="af-class-image-20" /></div>
                            <div className="af-class-div-block-20">
                              <div className="af-class-text-block-28">NEW TO YOU</div>
                              <div className="af-class-div-block-19">
                                <div className="af-class-text-block-29">Bungalow • Venice</div>
                                <div className="af-class-text-block-30">$3.4K</div>
                              </div>
                              <div className="af-class-div-block-19">
                                <div className="af-class-text-block-29 af-class-thin">3 Beds &nbsp;3 Baths</div>
                                <div className="af-class-text-block-30 af-class-thuin">Avail. Jul. 12</div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="af-class-slide-6 w-slide">
                          <a href="https://www.app.rentunicorn.com/create" className="af-class-link-block-8 w-inline-block">
                            <div className="af-class-div-block-18"><img src="images/Listing-Photo1.png" loading="lazy" sizes="(max-width: 479px) 80vw, (max-width: 767px) 250px, 100vw" srcSet="images/Listing-Photo1-p-500.png 500w, images/Listing-Photo1-p-800.png 800w, images/Listing-Photo1.png 915w" alt className="af-class-image-19" /><img src="images/heart_unfilled.svg" loading="lazy" alt className="af-class-image-20" /></div>
                            <div className="af-class-div-block-20">
                              <div className="af-class-text-block-28">NEW TO YOU</div>
                              <div className="af-class-div-block-19">
                                <div className="af-class-text-block-29">House • Santa Monica</div>
                                <div className="af-class-text-block-30">$2.9K</div>
                              </div>
                              <div className="af-class-div-block-19">
                                <div className="af-class-text-block-29 af-class-thin">1 Beds &nbsp;1.5 Baths</div>
                                <div className="af-class-text-block-30 af-class-thuin">Avail. Mar. 22</div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="af-class-slide-6 w-slide">
                          <a href="https://www.app.rentunicorn.com/create" className="af-class-link-block-9 w-inline-block">
                            <div className="af-class-div-block-18"><img src="images/Listing-Photo-2.png" loading="lazy" sizes="(max-width: 479px) 80vw, (max-width: 767px) 250px, 100vw" srcSet="images/Listing-Photo-2-p-500.png 500w, images/Listing-Photo-2-p-800.png 800w, images/Listing-Photo-2.png 915w" alt className="af-class-image-19" /><img src="images/heart_unfilled.svg" loading="lazy" alt className="af-class-image-20" /></div>
                            <div className="af-class-div-block-20">
                              <div className="af-class-text-block-28">NEW TO YOU</div>
                              <div className="af-class-div-block-19">
                                <div className="af-class-text-block-29 af-class-bbig">Apartment • Santa Monica</div>
                                <div className="af-class-text-block-30">$3.2K</div>
                              </div>
                              <div className="af-class-div-block-19">
                                <div className="af-class-text-block-29 af-class-thin">2 Beds &nbsp;2.5 Baths</div>
                                <div className="af-class-text-block-30 af-class-thuin">Avail. Apr. 29</div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="af-class-slide-6 w-slide">
                          <a href="https://www.app.rentunicorn.com/create" className="af-class-link-block-10 w-inline-block">
                            <div className="af-class-div-block-18"><img src="images/Listing-Photo-3.png" loading="lazy" sizes="(max-width: 479px) 80vw, (max-width: 767px) 250px, 100vw" srcSet="images/Listing-Photo-3-p-500.png 500w, images/Listing-Photo-3-p-800.png 800w, images/Listing-Photo-3.png 915w" alt className="af-class-image-19" /><img src="images/heart_unfilled.svg" loading="lazy" alt className="af-class-image-20" /></div>
                            <div className="af-class-div-block-20">
                              <div className="af-class-text-block-28">NEW TO YOU</div>
                              <div className="af-class-div-block-19">
                                <div className="af-class-text-block-29">House • Venice</div>
                                <div className="af-class-text-block-30">$4.7K</div>
                              </div>
                              <div className="af-class-div-block-19">
                                <div className="af-class-text-block-29 af-class-thin">4 Beds &nbsp;2.5 Baths</div>
                                <div className="af-class-text-block-30 af-class-thuin">Avail. Jun. 17</div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="af-class-left-arrow-2 w-slider-arrow-left">
                        <div className="w-icon-slider-left" />
                      </div>
                      <div className="af-class-right-arrow-2 w-slider-arrow-right">
                        <div className="w-icon-slider-right" />
                      </div>
                      <div className="af-class-slide-nav-2 w-slider-nav w-round" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-section-4 af-class-wf-section"><img src="images/wqw.png" loading="lazy" sizes="100vw" srcSet="images/wqw-p-500.png 500w, images/wqw-p-1080.png 1080w, images/wqw.png 1500w" alt className="af-class-mob" />
                <div className="af-class-main-container">
                  <div className="af-class-div-block-3"><img src="images/hero_unicorn_iphone-2.png" loading="eager" sizes="(max-width: 479px) 100vw, (max-width: 767px) 727.5px, (max-width: 991px) 96vw, (max-width: 1279px) 60vw, (max-width: 1919px) 753.984375px, 870px" srcSet="images/hero_unicorn_iphone-2-p-500.png 500w, images/hero_unicorn_iphone-2-p-800.png 800w, images/hero_unicorn_iphone-2-p-1080.png 1080w, images/hero_unicorn_iphone-2-p-1600.png 1600w, images/hero_unicorn_iphone-2-p-2000.png 2000w, images/hero_unicorn_iphone-2-p-2600.png 2600w, images/hero_unicorn_iphone-2-p-3200.png 3200w, images/hero_unicorn_iphone-2.png 3728w" alt className="af-class-image-2" />
                    <div className="af-class-div-block-4">
                      <h2 className="af-class-heading-2">Two steps to unlock access</h2>
                      <div className="af-class-div-block-5">
                        <div data-w-id="512fc833-6f56-9ea7-0f07-5234f25d074c" style={{opacity: 0}} className="af-class-paid-req">
                          <div className="af-class-circle">
                            <div className="af-class-text-block-5">1</div>
                          </div>
                          <div className="af-class-text-block-6">Rate your home</div>
                          <div className="af-class-text-block-7">Rate you current home’s noise level, brightness and landlord management. <br />(It takes 2 minutes)</div>
                        </div>
                        <div id="w-node-_14f9d9cc-758e-c6f0-b8be-08d2766bc93c-9f949015" data-w-id="14f9d9cc-758e-c6f0-b8be-08d2766bc93c" style={{opacity: 0}} className="af-class-paid-req">
                          <div className="af-class-circle">
                            <div className="af-class-text-block-5">2</div>
                          </div>
                          <div className="af-class-text-block-6">Show off your place</div>
                          <div className="af-class-text-block-7">Add a quick video walkthrough of your place. Give us a tour as if you were going to send it to your friends and family.</div>
                        </div>
                      </div>
                      <a data-w-id="be888714-2308-f94b-c842-3eca236e098e" style={{opacity: 0}} href="https://www.app.rentunicorn.com/create" className="af-class-button-2 w-button">Unlock Early Access</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-reviews-section af-class-wf-section">
                <div className="af-class-main-container af-class-bg">
                  <div className="af-class-revies-content-block">
                    <h2 className="af-class-h2-real-reviews">Real Reviews</h2>
                    <div className="af-class-text-block-21">Want to learn what it's like living in your future home before you decide to apply? Read verified reviews from the people that live there today.</div>
                    <div className="af-class-reviews-grid">
                      <div id="w-node-_8c9e3f9e-4af5-1f76-4c96-49b83d9e7cc6-9f949015" className="af-class-image-block">
                        <div>
                          <div data-delay={4000} data-animation="fade" className="af-class-slider-3 w-slider" data-autoplay="true" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit={0} data-nav-spacing={3} data-duration={1000} data-infinite="true">
                            <div className="w-slider-mask">
                              <div className="af-class-slide-9 w-slide"><img src="images/11Q.png" loading="lazy" sizes="(max-width: 479px) 44vw, 100vw" srcSet="images/11Q-p-500.png 500w, images/11Q-p-800.png 800w, images/11Q.png 1086w" alt className="af-class-in-mob-res" /><img src="images/11Q.png" loading="lazy" sizes="(max-width: 767px) 100vw, (max-width: 991px) 319.74609375px, (max-width: 1279px) 32vw, 360px" srcSet="images/11Q-p-500.png 500w, images/11Q-p-800.png 800w, images/11Q.png 1086w" alt className="af-class-group-image-pc" /></div>
                              <div className="w-slide"><img src="images/22Q.png" loading="lazy" alt className="af-class-group-image-pc" /><img src="images/22Q.png" loading="lazy" alt className="af-class-in-mob-res" /></div>
                              <div className="w-slide"><img src="images/33Q.png" loading="lazy" alt className="af-class-group-image-pc" /><img src="images/33Q.png" loading="lazy" alt className="af-class-in-mob-res" /></div>
                              <div className="w-slide"><img src="images/44Q.png" loading="lazy" sizes="(max-width: 767px) 100vw, (max-width: 991px) 319.74609375px, (max-width: 1279px) 32vw, 360px" srcSet="images/44Q-p-500.png 500w, images/44Q.png 724w" alt className="af-class-group-image-pc" /><img src="images/44Q.png" loading="lazy" sizes="(max-width: 479px) 44vw, 100vw" srcSet="images/44Q-p-500.png 500w, images/44Q.png 724w" alt className="af-class-in-mob-res" /></div>
                            </div>
                            <div className="af-class-left-arrow-3 w-slider-arrow-left">
                              <div className="w-icon-slider-left" />
                            </div>
                            <div className="af-class-right-arrow-3 w-slider-arrow-right">
                              <div className="w-icon-slider-right" />
                            </div>
                            <div className="af-class-slide-nav-3 w-slider-nav w-round" />
                          </div>
                        </div>
                      </div>
                      <div id="w-node-_13616b9f-3afc-e5aa-384b-a62d13c0dd55-9f949015" className="af-class-reviews-cards">
                        <div id="w-node-d4b6342d-3619-6720-9c40-8d67a1105297-9f949015" className="af-class-reviewes-content"><img src="images/Landlord.svg" loading="lazy" alt="Landlord" className="af-class-image-13" />
                          <div className="af-class-text-block-8">Landlord</div>
                          <div className="af-class-text-block-9 af-class-margin">Fixes issues within days of calling.</div>
                          <div className="af-class-text-block-10">5.0</div>
                          <div className="af-class-outer-track">
                            <div className="af-class-inner-track">
                              <div className="af-class-filled-track" />
                            </div>
                          </div>
                        </div>
                        <div id="w-node-e2f35570-4bb7-cd61-44f2-9b5eca6cbe8a-9f949015" className="af-class-reviewes-content"><img src="images/Natural-Light.svg" loading="lazy" data-w-id="e2f35570-4bb7-cd61-44f2-9b5eca6cbe8b" alt className="af-class-image-11" />
                          <div className="af-class-text-block-8">Natural Light</div>
                          <div className="af-class-text-block-9">Amazing light all day long.</div>
                          <div className="af-class-text-block-10">4.7</div>
                          <div className="af-class-outer-track">
                            <div className="af-class-inner-track">
                              <div className="af-class-filled-track af-class-_4-7" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="w-node-_2248d3b5-9ef8-6503-2ef5-881033043106-9f949015" className="af-class-reviews-cards">
                        <div className="af-class-reviewes-content"><img src="images/Sound.svg" loading="lazy" data-w-id="2248d3b5-9ef8-6503-2ef5-881033043108" alt className="af-class-image-10" />
                          <div className="af-class-text-block-8">Noise Level</div>
                          <div className="af-class-text-block-9 af-class-margin">Compared to rush hour traffic.</div>
                          <div className="af-class-text-block-10">3.5</div>
                          <div className="af-class-outer-track">
                            <div className="af-class-inner-track">
                              <div className="af-class-filled-track af-class-_3-5" />
                            </div>
                          </div>
                        </div>
                        <div id="w-node-_2248d3b5-9ef8-6503-2ef5-881033043112-9f949015" className="af-class-reviewes-content"><img src="images/Walk-Score.svg" loading="lazy" data-w-id="2248d3b5-9ef8-6503-2ef5-881033043113" alt className="af-class-image-12" />
                          <div className="af-class-text-block-8">Walkable</div>
                          <div className="af-class-text-block-9 af-class-margin">Need to drive to get to essentials.</div>
                          <div className="af-class-text-block-10">3.0</div>
                          <div className="af-class-outer-track">
                            <div className="af-class-inner-track">
                              <div className="af-class-filled-track af-class-_3-0" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-for-mob">
                      <div className="af-class-reviews-cards af-class-mob">
                        <div id="w-node-def97db3-cf9b-cab2-3ee7-3afa23de12dc-9f949015" className="af-class-reviewes-content af-class-full-width"><img src="images/Landlord.svg" loading="lazy" alt="Landlord" className="af-class-image-13" />
                          <div className="af-class-text-block-8">Landlord</div>
                          <div className="af-class-text-block-9">Fixes issues within days of calling.</div>
                          <div className="af-class-text-block-10">5.0</div>
                          <div className="af-class-outer-track">
                            <div className="af-class-inner-track">
                              <div className="af-class-filled-track" />
                            </div>
                          </div>
                        </div>
                        <div id="w-node-def97db3-cf9b-cab2-3ee7-3afa23de12e7-9f949015" className="af-class-reviewes-content af-class-full-width"><img src="images/Natural-Light.svg" loading="lazy" alt className="af-class-image-11" />
                          <div className="af-class-text-block-8">Natural Light</div>
                          <div className="af-class-text-block-9">Amazing light all day long.</div>
                          <div className="af-class-text-block-10">4.7</div>
                          <div className="af-class-outer-track">
                            <div className="af-class-inner-track">
                              <div className="af-class-filled-track af-class-_4-7" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="af-class-reviews-cards af-class-mob">
                        <div id="w-node-_8ec10df8-0e0e-971a-92e3-b7cdb487014b-9f949015" className="af-class-reviewes-content af-class-full-width"><img src="images/Sound.svg" loading="lazy" alt className="af-class-image-10" />
                          <div className="af-class-text-block-8">Noise Level</div>
                          <div className="af-class-text-block-9">Compared to rush hour traffic.</div>
                          <div className="af-class-text-block-10">3.5</div>
                          <div className="af-class-outer-track">
                            <div className="af-class-inner-track">
                              <div className="af-class-filled-track af-class-_3-5" />
                            </div>
                          </div>
                        </div>
                        <div id="w-node-_8ec10df8-0e0e-971a-92e3-b7cdb4870156-9f949015" className="af-class-reviewes-content af-class-full-width"><img src="images/Walk-Score.svg" loading="lazy" alt className="af-class-image-12" />
                          <div className="af-class-text-block-8">Walkable</div>
                          <div className="af-class-text-block-9">Fixes issues within days of calling.</div>
                          <div className="af-class-text-block-10">3.0</div>
                          <div className="af-class-outer-track">
                            <div className="af-class-inner-track">
                              <div className="af-class-filled-track af-class-_3-0" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div data-delay={4000} data-animation="slide" className="af-class-slider-5 w-slider" data-autoplay="false" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit={0} data-nav-spacing={3} data-duration={500} data-infinite="true">
                        <div className="af-class-mask-4 w-slider-mask">
                          <div className="af-class-slide-8 w-slide">
                            <div className="af-class-reviewes-content af-class-full-width"><img loading="lazy" src="images/Landlord.svg" alt="Landlord" className="af-class-image-13" />
                              <div className="af-class-text-block-8">Landlord</div>
                              <div className="af-class-text-block-9">Fixes issues within days of calling.</div>
                              <div className="af-class-text-block-10">5.0</div>
                              <div className="af-class-outer-track">
                                <div className="af-class-inner-track">
                                  <div className="af-class-filled-track" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-slide">
                            <div className="af-class-reviewes-content af-class-full-width"><img loading="lazy" src="images/Natural-Light.svg" alt className="af-class-image-11" />
                              <div className="af-class-text-block-8">Natural Light</div>
                              <div className="af-class-text-block-9">Amazing light all day long.</div>
                              <div className="af-class-text-block-10">4.7</div>
                              <div className="af-class-outer-track">
                                <div className="af-class-inner-track">
                                  <div className="af-class-filled-track af-class-_4-7" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-slide">
                            <div className="af-class-reviewes-content af-class-full-width"><img src="images/Sound.svg" loading="lazy" alt className="af-class-image-10" />
                              <div className="af-class-text-block-8">Noise Level</div>
                              <div className="af-class-text-block-9">Compared to rush hour traffic.</div>
                              <div className="af-class-text-block-10">3.5</div>
                              <div className="af-class-outer-track">
                                <div className="af-class-inner-track">
                                  <div className="af-class-filled-track af-class-_3-5" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-slide">
                            <div className="af-class-reviewes-content af-class-full-width"><img src="images/Walk-Score.svg" loading="lazy" alt className="af-class-image-12" />
                              <div className="af-class-text-block-8">Walkable</div>
                              <div className="af-class-text-block-9">Fixes issues within days of calling.</div>
                              <div className="af-class-text-block-10">3.0</div>
                              <div className="af-class-outer-track">
                                <div className="af-class-inner-track">
                                  <div className="af-class-filled-track af-class-_3-0" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="af-class-left-arrow-5 w-slider-arrow-left">
                          <div className="w-icon-slider-left" />
                        </div>
                        <div className="af-class-right-arrow-5 w-slider-arrow-right">
                          <div className="w-icon-slider-right" />
                        </div>
                        <div className="af-class-slide-nav-5 w-slider-nav w-round" />
                      </div>
                    </div>
                    <div className="af-class-unlock-exclusive-acess">
                      <div id="w-node-e67347a8-c4d7-dfc4-4786-a5b9ee79f0a4-9f949015" className="af-class-section-image af-class-for-responsiveness"><img src="images/Section-Image.png" loading="lazy" sizes="(max-width: 479px) 92vw, 100vw" srcSet="images/Section-Image-p-500.png 500w, images/Section-Image.png 818w" alt className="af-class-image-4" /></div>
                      <div id="w-node-_528397ab-5a78-876f-a4c0-a6f02ac18be5-9f949015" data-w-id="528397ab-5a78-876f-a4c0-a6f02ac18be5" style={{opacity: 0}} className="af-class-unlock-acess">
                        <div className="af-class-text-block-11">Sign up to unlock access in 2 mins or less!</div>
                        <a href="https://www.app.rentunicorn.com/discover" className="af-class-unlock w-button">Start Searching</a>
                      </div>
                      <div id="w-node-_5e1aa519-b4a9-3543-cf9c-08bbc97a2948-9f949015" className="af-class-section-image"><img src="images/Section-Image.png" loading="lazy" style={{opacity: 0}} data-w-id="76274169-b2f4-c080-af65-3c38bad48c0e" srcSet="images/Section-Image-p-500.png 500w, images/Section-Image.png 818w" sizes="(max-width: 479px) 100vw, (max-width: 767px) 52vw, (max-width: 991px) 46vw, 409.00390625px" alt className="af-class-image-4" /></div>
                    </div>
                  </div>
                </div><img src="images/Group-2249.svg" loading="lazy" alt="Background Grid 2" className="af-class-image-15" />
              </div>
              <div className="af-class-who-we-are-section af-class-wf-section">
                <div className="af-class-main-container">
                  <div className="af-class-who-we-are">
                    <h2 className="af-class-h2-who-we-are">Popular Cities</h2>
                    <div className="af-class-div-block-14">
                      <div className="af-class-alphabet">A</div>
                      <div className="af-class-alphabet">e</div>
                      <div className="af-class-alphabet">m</div>
                      <div className="af-class-alphabet">t</div>
                      <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                        <div className="af-class-text-block-13">Agoura Hills CA</div>
                      </a>
                      <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                        <div className="af-class-text-block-13">El Segundo, CA</div>
                      </a>
                      <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                        <div className="af-class-text-block-13">Malibu, CA</div>
                      </a>
                      <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                        <div className="af-class-text-block-13">Torrance, CA</div>
                      </a>
                      <div id="w-node-f6d76f34-22a2-18fe-f488-c3cd2bdfecba-9f949015" className="af-class-alphabet">b</div>
                      <div id="w-node-c57a93d8-79c7-df6c-142e-9038d86ef181-9f949015" className="af-class-alphabet">g</div>
                      <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                        <div className="af-class-text-block-13">Manhattan Beach, CA</div>
                      </a>
                      <div id="w-node-_9abe3223-1804-0b0d-bf9d-9318fb402d98-9f949015" className="af-class-alphabet">v</div>
                      <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                        <div className="af-class-text-block-13">Baldwin Park, CA</div>
                      </a>
                      <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                        <div className="af-class-text-block-13">Glendale, CA</div>
                      </a>
                      <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                        <div className="af-class-text-block-13">Mid City, CA</div>
                      </a>
                      <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                        <div className="af-class-text-block-13">Venice, CA</div>
                      </a>
                      <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">Beverly Hills, CA</div>
                      </a>
                      <div id="w-node-_208fb7d6-d4d6-3056-f96c-145ece184f93-9f949015" className="af-class-alphabet">h</div>
                      <div id="w-node-_5afb93c3-e5bc-8987-9a23-0f2b576dc41f-9f949015" className="af-class-alphabet">p</div>
                      <div id="w-node-b373acb4-6d34-9080-9265-f0c83734b306-9f949015" className="af-class-alphabet">w</div>
                      <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">Beverlywood, CA</div>
                      </a>
                      <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">Hermosa Beach, CA</div>
                      </a>
                      <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">Pasadena, CA</div>
                      </a>
                      <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">West Hollywood, CA</div>
                      </a>
                      <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">Brentwood, CA</div>
                      </a>
                      <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">Hollywood, CA</div>
                      </a>
                      <div id="w-node-_917ee05c-cbdb-cc44-41f1-1dcc08b7c14c-9f949015" className="af-class-alphabet">r</div>
                      <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">Weslake, CA</div>
                      </a>
                      <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">Burbank, CA</div>
                      </a>
                      <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">Inglewood, CA</div>
                      </a>
                      <a id="w-node-_29a974c5-44e4-da8a-6bbb-3d4aed717713-9f949015" href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">Redondo Beach, CA</div>
                      </a>
                      <div id="w-node-_29a5da27-7d8f-5a79-29b6-95d9c21f270d-9f949015" className="af-class-alphabet">c</div>
                      <div id="w-node-_6934246f-c012-4620-415a-26ca98ee9aac-9f949015" className="af-class-alphabet">l</div>
                      <div id="w-node-_19a6a745-babb-018d-b815-5633c25b0ec7-9f949015" className="af-class-alphabet">s</div>
                      <a id="w-node-b15368a2-182a-4ab2-dee0-731525e780f2-9f949015" href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">Calabasas, CA</div>
                      </a>
                      <a id="w-node-a33eb6d7-2408-fb11-a2cb-f0edd5e4fb56-9f949015" href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">Long Beach, CA</div>
                      </a>
                      <a id="w-node-_7ff65bbd-9126-7444-b769-f74be2f81462-9f949015" href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">San Fernando, CA</div>
                      </a>
                      <a id="w-node-_5fb1b216-576d-30b1-7d18-fa5a7a8ee78d-9f949015" href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">Culver City, CA</div>
                      </a>
                      <a id="w-node-_9a7c63ef-29cc-dea3-4d56-c7f3d11c8334-9f949015" href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">Los Angeles, CA</div>
                      </a>
                      <a id="w-node-dbe404cc-54de-c377-ef6c-986f57b6a27c-9f949015" href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">San Gabriel, CA</div>
                      </a>
                      <div id="w-node-_164d200c-4c6a-5b9f-3670-b493438324a8-9f949015" className="af-class-alphabet">d</div>
                      <a id="w-node-dd70613d-27b6-d492-887e-18aed49f4e73-9f949015" href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">Santa Monica, CA</div>
                      </a>
                      <a id="w-node-b95610ea-f2b1-ff5a-44c0-32c61d2feb16-9f949015" href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                        <div className="af-class-text-block-13">Downtown LA, CA</div>
                      </a>
                    </div>
                    <div className="af-class-locations-grid">
                      <div className="af-class-loactions">
                        <div className="af-class-address-link-warpper">
                          <div className="af-class-text-block-12">A</div>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Agoura Hills CA</div>
                          </a>
                        </div>
                        <div className="af-class-address-link-warpper">
                          <div className="af-class-text-block-12">B</div>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Baldwin Park, CA</div>
                          </a>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Beverly Hills, CA</div>
                          </a>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Beverlywood, CA</div>
                          </a>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Brentwood, CA</div>
                          </a>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Burbank, CA</div>
                          </a>
                        </div>
                        <div className="af-class-address-link-warpper">
                          <div className="af-class-text-block-12">C</div>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Calabasas, CA</div>
                          </a>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Culver City, CA</div>
                          </a>
                        </div>
                        <div className="af-class-address-link-warpper af-class-remove-margin">
                          <div className="af-class-text-block-12">D</div>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Downtown LA, CA</div>
                          </a>
                        </div>
                      </div>
                      <div id="w-node-_3754acf7-ea3d-7811-8729-abba6e30561e-9f949015" className="af-class-loactions">
                        <div className="af-class-address-link-warpper">
                          <div className="af-class-text-block-12">E</div>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">El Segundo, CA</div>
                          </a>
                        </div>
                        <div className="af-class-address-link-warpper">
                          <div className="af-class-text-block-12">G</div>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Glendale, CA</div>
                          </a>
                        </div>
                        <div className="af-class-address-link-warpper">
                          <div className="af-class-text-block-12">H</div>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Hermosa Beach, CA</div>
                          </a>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Hollywood, CA</div>
                          </a>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Inglewood, CA</div>
                          </a>
                        </div>
                        <div className="af-class-address-link-warpper">
                          <div className="af-class-text-block-12">L</div>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Long Beach, CA</div>
                          </a>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Los Angeles, CA</div>
                          </a>
                        </div>
                      </div>
                      <div id="w-node-efb2bf83-b592-5b69-8c22-cee35e94697d-9f949015" className="af-class-loactions">
                        <div className="af-class-address-link-warpper af-class-margin">
                          <div className="af-class-text-block-12">M</div>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Malibu, CA</div>
                          </a>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Manhattan Beach, CA</div>
                          </a>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Mid City, CA</div>
                          </a>
                        </div>
                        <div className="af-class-address-link-warpper">
                          <div className="af-class-text-block-12">P</div>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Pasadena, CA</div>
                          </a>
                        </div>
                        <div className="af-class-address-link-warpper af-class-margin1">
                          <div className="af-class-text-block-12">R</div>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Redondo Beach, CA</div>
                          </a>
                        </div>
                        <div className="af-class-address-link-warpper">
                          <div className="af-class-text-block-12">S</div>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">San Fernando, CA</div>
                          </a>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">San Gabriel, CA</div>
                          </a>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Santa Monica, CA</div>
                          </a>
                        </div>
                      </div>
                      <div id="w-node-c629be07-a28e-34d7-b141-97b148630a43-9f949015" className="af-class-loactions">
                        <div className="af-class-address-link-warpper">
                          <div className="af-class-text-block-12">T</div>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Torrance, CA</div>
                          </a>
                        </div>
                        <div className="af-class-address-link-warpper">
                          <div className="af-class-text-block-12">V</div>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Venice, CA</div>
                          </a>
                        </div>
                        <div className="af-class-address-link-warpper">
                          <div className="af-class-text-block-12">W</div>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">West Hollywood, CA</div>
                          </a>
                          <a href="#" className="af-class-link-block w-inline-block">
                            <div className="af-class-text-block-13">Weslake, CA</div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div data-delay={4000} data-animation="slide" className="af-class-slider w-slider" data-autoplay="false" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit={0} data-nav-spacing={2} data-duration={500} data-infinite="true">
                      <div className="af-class-mask w-slider-mask">
                        <div className="af-class-slide w-slide">
                          <div className="af-class-loactions">
                            <div className="af-class-address-link-warpper">
                              <div className="af-class-text-block-12">A</div>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Agoura Hills CA</div>
                              </a>
                            </div>
                            <div className="af-class-address-link-warpper af-class-padding af-class-redued-padding">
                              <div className="af-class-text-block-12">B</div>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Baldwin Park, CA</div>
                              </a>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Beverly Hills, CA</div>
                              </a>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Beverlywood, CA</div>
                              </a>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Brentwood, CA</div>
                              </a>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Burbank, CA</div>
                              </a>
                            </div>
                            <div className="af-class-address-link-warpper af-class-marginn">
                              <div className="af-class-text-block-12">C</div>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Calabasas, CA</div>
                              </a>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Culver City, CA</div>
                              </a>
                            </div>
                            <div className="af-class-address-link-warpper af-class-remove-margin">
                              <div className="af-class-text-block-12">D</div>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Downtown LA, CA</div>
                              </a>
                            </div>
                          </div>
                          <div>
                            <div className="af-class-alphabet">A</div>
                            <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                              <div className="af-class-text-block-13">Agoura Hills CA</div>
                            </a>
                            <div className="af-class-alphabet">b</div>
                            <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                              <div className="af-class-text-block-13">Baldwin Park, CA</div>
                            </a>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">Beverly Hills, CA</div>
                            </a>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">Beverlywood, CA</div>
                            </a>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">Brentwood, CA</div>
                            </a>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">Burbank, CA</div>
                            </a>
                            <div className="af-class-alphabet">c</div>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">Calabasas, CA</div>
                            </a>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">Culver City, CA</div>
                            </a>
                            <div className="af-class-alphabet">d</div>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">Downtown LA, CA</div>
                            </a>
                          </div>
                        </div>
                        <div className="af-class-slide-2 w-slide">
                          <div className="af-class-loactions">
                            <div className="af-class-address-link-warpper">
                              <div className="af-class-text-block-12">E</div>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">El Segundo, CA</div>
                              </a>
                            </div>
                            <div className="af-class-address-link-warpper af-class-padding">
                              <div className="af-class-text-block-12">G</div>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Glendale, CA</div>
                              </a>
                            </div>
                            <div className="af-class-address-link-warpper af-class-pading">
                              <div className="af-class-text-block-12">H</div>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Hermosa Beach, CA</div>
                              </a>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Hollywood, CA</div>
                              </a>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Inglewood, CA</div>
                              </a>
                            </div>
                            <div className="af-class-address-link-warpper">
                              <div className="af-class-text-block-12">L</div>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Long Beach, CA</div>
                              </a>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Los Angeles, CA</div>
                              </a>
                            </div>
                          </div>
                          <div>
                            <div className="af-class-alphabet">e</div>
                            <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                              <div className="af-class-text-block-13">El Segundo, CA</div>
                            </a>
                            <div className="af-class-alphabet">g</div>
                            <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                              <div className="af-class-text-block-13">Glendale, CA</div>
                            </a>
                            <div className="af-class-alphabet">h</div>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">Hermosa Beach, CA</div>
                            </a>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">Hollywood, CA</div>
                            </a>
                            <div className="af-class-alphabet">I</div>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">Inglewood, CA</div>
                            </a>
                            <div className="af-class-alphabet">l</div>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">Long Beach, CA</div>
                            </a>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">Los Angeles, CA</div>
                            </a>
                          </div>
                        </div>
                        <div className="af-class-slide-3 w-slide">
                          <div className="af-class-loactions">
                            <div className="af-class-address-link-warpper af-class-margin">
                              <div className="af-class-text-block-12">M</div>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Malibu, CA</div>
                              </a>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Manhattan Beach, CA</div>
                              </a>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Mid City, CA</div>
                              </a>
                            </div>
                            <div className="af-class-address-link-warpper af-class-pading af-class-reduedapdding">
                              <div className="af-class-text-block-12">P</div>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Pasadena, CA</div>
                              </a>
                            </div>
                            <div className="af-class-address-link-warpper af-class-margin1 af-class-padding">
                              <div className="af-class-text-block-12">R</div>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Redondo Beach, CA</div>
                              </a>
                            </div>
                            <div className="af-class-address-link-warpper af-class-pading2">
                              <div className="af-class-text-block-12">S</div>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">San Fernando, CA</div>
                              </a>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">San Gabriel, CA</div>
                              </a>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Santa Monica, CA</div>
                              </a>
                            </div>
                          </div>
                          <div>
                            <div className="af-class-alphabet">m</div>
                            <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                              <div className="af-class-text-block-13">Malibu, CA</div>
                            </a>
                            <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                              <div className="af-class-text-block-13">Manhattan Beach, CA</div>
                            </a>
                            <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                              <div className="af-class-text-block-13">Mid City, CA</div>
                            </a>
                            <div className="af-class-alphabet">p</div>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">Pasadena, CA</div>
                            </a>
                            <div className="af-class-alphabet">r</div>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">Redondo Beach, CA</div>
                            </a>
                            <div className="af-class-alphabet">s</div>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">San Fernando, CA</div>
                            </a>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">San Gabriel, CA</div>
                            </a>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">Santa Monica, CA</div>
                            </a>
                          </div>
                        </div>
                        <div className="af-class-slide-4 w-slide">
                          <div className="af-class-loactions">
                            <div className="af-class-address-link-warpper">
                              <div className="af-class-text-block-12">T</div>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Torrance, CA</div>
                              </a>
                            </div>
                            <div className="af-class-address-link-warpper af-class-padding af-class-vpadding">
                              <div className="af-class-text-block-12">V</div>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Venice, CA</div>
                              </a>
                            </div>
                            <div className="af-class-address-link-warpper af-class-pading">
                              <div className="af-class-text-block-12">W</div>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">West Hollywood, CA</div>
                              </a>
                              <a href="#" className="af-class-link-block w-inline-block">
                                <div className="af-class-text-block-13">Weslake, CA</div>
                              </a>
                            </div>
                          </div>
                          <div>
                            <div className="af-class-alphabet">t</div>
                            <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                              <div className="af-class-text-block-13">Torrance, CA</div>
                            </a>
                            <div className="af-class-alphabet">v</div>
                            <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                              <div className="af-class-text-block-13">Venice, CA</div>
                            </a>
                            <div className="af-class-alphabet">w</div>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">West Hollywood, CA</div>
                            </a>
                            <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                              <div className="af-class-text-block-13">Weslake, CA</div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="af-class-left-arrow w-slider-arrow-left">
                        <div className="af-class-icon-3 w-icon-slider-left" />
                      </div>
                      <div className="af-class-right-arrow w-slider-arrow-right">
                        <div className="af-class-icon-2 w-icon-slider-right" />
                        <div className="af-class-div-block-13"><img src="images/Right-Arrow.svg" loading="lazy" alt /></div>
                      </div>
                      <div className="af-class-slide-nav w-slider-nav w-slider-nav-invert w-round" />
                    </div>
                    <div className="af-class-text-block-23">[1] For illustrative purposes only; results may vary. Estimates are based on average market payouts for tenants who register their unit on Unicorn.</div>
                    <div className="af-class-div-block-21">
                      <div className="af-class-div-block-40">
                        <div className="af-class-alphabet">A</div>
                        <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                          <div className="af-class-text-block-13">Agoura Hills CA</div>
                        </a>
                        <div className="af-class-alphabet">b</div>
                        <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                          <div className="af-class-text-block-13">Baldwin Park, CA</div>
                        </a>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">Beverly Hills, CA</div>
                        </a>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">Beverlywood, CA</div>
                        </a>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">Brentwood, CA</div>
                        </a>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">Burbank, CA</div>
                        </a>
                        <div className="af-class-alphabet">c</div>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">Calabasas, CA</div>
                        </a>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">Culver City, CA</div>
                        </a>
                        <div className="af-class-alphabet">d</div>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">Downtown LA, CA</div>
                        </a>
                      </div>
                      <div className="af-class-div-block-41">
                        <div className="af-class-alphabet">e</div>
                        <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                          <div className="af-class-text-block-13">El Segundo, CA</div>
                        </a>
                        <div className="af-class-alphabet">g</div>
                        <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                          <div className="af-class-text-block-13">Glendale, CA</div>
                        </a>
                        <div className="af-class-alphabet">h</div>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">Hermosa Beach, CA</div>
                        </a>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">Hollywood, CA</div>
                        </a>
                        <div className="af-class-alphabet">I</div>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">Inglewood, CA</div>
                        </a>
                        <div className="af-class-alphabet">l</div>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">Long Beach, CA</div>
                        </a>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">Los Angeles, CA</div>
                        </a>
                      </div>
                      <div className="af-class-div-block-42">
                        <div className="af-class-alphabet">m</div>
                        <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                          <div className="af-class-text-block-13">Malibu, CA</div>
                        </a>
                        <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                          <div className="af-class-text-block-13">Manhattan Beach, CA</div>
                        </a>
                        <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                          <div className="af-class-text-block-13">Mid City, CA</div>
                        </a>
                        <div className="af-class-alphabet">p</div>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">Pasadena, CA</div>
                        </a>
                        <div className="af-class-alphabet">r</div>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">Redondo Beach, CA</div>
                        </a>
                        <div className="af-class-alphabet">s</div>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">San Fernando, CA</div>
                        </a>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">San Gabriel, CA</div>
                        </a>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">Santa Monica, CA</div>
                        </a>
                      </div>
                      <div className="af-class-div-block-43">
                        <div className="af-class-alphabet">t</div>
                        <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                          <div className="af-class-text-block-13">Torrance, CA</div>
                        </a>
                        <div className="af-class-alphabet">v</div>
                        <a href="https://www.app.rentunicorn.com" className="af-class-link-block af-class-removemargin w-inline-block">
                          <div className="af-class-text-block-13">Venice, CA</div>
                        </a>
                        <div className="af-class-alphabet">w</div>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">West Hollywood, CA</div>
                        </a>
                        <a href="https://www.app.rentunicorn.com" className="af-class-adress-link w-inline-block">
                          <div className="af-class-text-block-13">Weslake, CA</div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-footer af-class-wf-section">
                <div className="af-class-main-container">
                  <div className="af-class-footer-content">
                    <div className="af-class-footer-grid">
                      <div id="w-node-efa66326-1da2-f0d3-8067-1a62bb9b1d4c-9f949015" className="af-class-logo-block">
                        <a href="/" aria-current="page" className="af-class-link-block-2 w-inline-block w--current"><img src="images/Small-Logo.svg" loading="lazy" alt="Unicorn Logo" className="af-class-image-14" /></a>
                        <div className="af-class-text-block-15">Unicorn is a video-first apartment listing platform, powered by renters.</div>
                        <a href="sms:&body=Check%20out%20Unicorn%20🦄%20LA’s%20most%20exclusive%20apartment%20rental%20platform.%20https://www.rentunicorn.com/" className="af-class-invite-a-friend af-class-white w-button">Invite a friend</a>
                      </div>
                      <div id="w-node-dacb2fe3-1fbc-6366-5766-b95167b0a017-9f949015" className="af-class-footer-links">
                        <a href="https://rentunicorn.typeform.com/to/xYiZ2bos" className="af-class-links-item">About</a>
                        <a href="https://rentunicorn.typeform.com/to/xYiZ2bos" target="_blank" className="af-class-links-item af-class-white">Careers</a>
                        <a href="https://rentunicorn.typeform.com/to/W17RIkva" target="_blank" className="af-class-links-item af-class-white">Become a Scout</a>
                      </div>
                      <div id="w-node-da329920-af74-83d2-551c-ad388b80e651-9f949015" className="af-class-footer-links">
                        <a href="mailto:support@rentunicorn.com?subject=Unicorn%20Support" className="af-class-links-item">Support</a>
                        <a href="mailto:support@rentunicorn.com?subject=Unicorn%20Support" className="af-class-links-item af-class-white">Contact</a>
                        <a href="https://www.jotform.com/form/213430332418142" target="_blank" className="af-class-links-item af-class-white">Add a Listing</a>
                      </div>
                      <div id="w-node-_0a0d4d88-c8f3-5752-767c-da0b032281b9-9f949015" className="af-class-footer-links">
                        <a href="mailto:support@rentunicorn.com?subject=Unicorn%20Support" className="af-class-links-item">Follow Us</a>
                        <div className="af-class-social-links">
                          <a href="https://www.instagram.com/rentunicorn/" target="_blank" className="af-class-social-icons w-inline-block"><img src="images/Subtract.svg" loading="lazy" alt="Instagram Link" /></a>
                          <a href="https://www.tiktok.com/@rentunicorn?" target="_blank" className="af-class-social-icons w-inline-block"><img src="images/Vector.svg" loading="lazy" alt="TikTok Link" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-footer-divider" />
                    <div className="af-class-post-footer-content">
                      <div className="af-class-copyright">
                        <div className="af-class-div-block-12">
                          <a href="#" className="w-inline-block"><img src="images/Group-2249_1.svg" loading="lazy" alt="Equal Housing" /></a>
                          <div className="af-class-text-block-17">Equal Housing<br />Opportunity</div>
                        </div>
                        <div className="af-class-text-block-19">Copyright © 2021. Unicorn. All rights reserved.</div>
                      </div>
                      <div id="w-node-d2ce8758-5603-f7c0-6ca6-73516a0e7c94-9f949015" className="af-class-terms-and-condition">
                        <a href="terms" className="af-class-link-2">Terms &amp; Conditions •</a>
                        <a href="privacy" className="af-class-link-2"> Privacy Policy</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
            <style dangerouslySetInnerHTML={{__html: "\n.af-view .w-slider-dot {\n    background: #F9F9F9 !important ;\n}\n.af-view .w-slider-dot.w-active {\n    background: #FF70A3  !important ;\n}\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default IndexView

/* eslint-enable */