import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { IndexController, PrivacyController, TermsController, LandlordsController } from './controllers';

const paths = [
  'terms',
  'privacy',
  'landlords'
];

const Router = () => (
  <Switch>
    <Route path="/terms" component={TermsController} />
    <Route path="/privacy" component={PrivacyController} />
    <Route path="/landlords" component={LandlordsController} />
    <Route path="/" exact component={IndexController} />
    <Route render={(props) => {
      const route = paths.find((path => props.location.pathname && props.location.pathname.match(`/${path}`)))
      return <Redirect to={route || "/"} />
    }}/>
  </Switch>
);

export default Router;
