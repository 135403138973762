/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60dd6557d841571629949016").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
  { loading: fetch("https://cdnjs.cloudflare.com/ajax/libs/typed.js/2.0.10/typed.min.js").then(body => body.text()), isAsync: false },
  { loading: Promise.resolve("var typed=new Typed(\"#typed-words\",{strings:[\"10x Faster lease-up time\",\"12-24% Increase in NOI\",\"Happier Renters\"],typeSpeed:90,backSpeed:50,backDelay:1e3,startDelay:500,loop:!0,showCursor:!1,cursorChar:\"|\",attr:null});"), isAsync: false },
]

let Controller

class LandlordsView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/LandlordsController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = LandlordsView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '61d9d953f00508679ea8feb8'
    htmlEl.dataset['wfSite'] = '60dd6557d841571629949016'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = LandlordsView.Controller !== LandlordsView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/rentunicorn.webflow.css);


          /* add custom cursor */
          #typed-words::after {
            content: \"|\";
            display: inline;
            animation: blink 1s infinite;
          }
          /* custom cursor animation */
          @keyframes blink {
            0% {
              opacity: 1;
            }
            50% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        ` }} />
        <span className="af-view">
          <div className="af-class-body-2">
            <div data-collapse="medium" data-animation="default" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="af-class-navbar w-nav">
              <div className="af-class-nav_wrapper">
                <div className="af-class-nav-container">
                  <a href="/" className="af-class-brand w-nav-brand"><img src="images/Group-1907.svg" loading="lazy" alt="Unicorn" className="af-class-image-5" /></a>
                  <nav role="navigation" className="af-class-nav-menu w-nav-menu">
                    <a href="landlords" aria-current="page" className="af-class-div-block-15 w-inline-block w--current">
                      <div className="af-class-text-block-24">Brokers / Landlords</div>
                    </a>
                    <a href="https://rentunicorn.typeform.com/to/W17RIkva" className="af-class-div-block-15 w-inline-block">
                      <div className="af-class-text-block-24">Become a Scout</div>
                    </a>
                    <a href="https://www.app.rentunicorn.com/" className="af-class-button-3 w-button">Login / Sign Up</a>
                  </nav>
                  <div className="af-class-menu-button w-nav-button">
                    <div className="af-class-icon w-icon-nav-menu" />
                    <div className="af-class-menu-line" />
                    <div className="af-class-menu-line af-class-margin" />
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-over-flow-hidden-wrapper">
              <div className="af-class-section-5 af-class-wf-section">
                <div className="af-class-div-block-22">
                  <div className="af-class-text-block-31">Why landlords choose Unicorn</div>
                  <div className="af-class-div-block-23">
                    <div className="af-class-div-block-36">
                      <div id="typed-words" className="af-class-typed-words" />
                    </div>
                    <div className="af-class-div-block-24">
                      <div className="af-class-text-block-33">Unicorn is a video-first rental listing platform that specializes in reducing vacancy to zero.</div>
                      <div className="af-class-div-block-25">
                        <a href="https://www.jotform.com/form/213430332418142" target="_blank" className="af-class-hero-btn af-class-small af-class-big w-button">List your rental</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-section-6 af-class-wf-section">
                <div className="af-class-div-block-22 af-class-hori">
                  <div>
                    <div className="af-class-div-block-4 af-class-new">
                      <h2 className="af-class-heading-2 af-class-new af-class-_1st">Two steps to unlock free leads.</h2>
                    </div>
                  </div>
                  <div>
                    <div className="af-class-div-block-5">
                      <div data-w-id="bfb85fcb-d563-f9f6-9e81-b3e57eb3b6cd" style={{opacity: 0}} className="af-class-paid-req af-class-new">
                        <div className="af-class-circle af-class-white">
                          <div className="af-class-text-block-5 af-class-white">1</div>
                        </div>
                        <div className="af-class-div-block-27">
                          <div>
                            <div className="af-class-text-block-6 af-class-white">Upload your unit</div>
                            <div className="af-class-text-block-7 af-class-white">Fill out our upload form just like you would on any traditional listing site. Add a quick video walkthrough to maximise engagement.</div>
                          </div>
                          <a href="https://www.jotform.com/form/213430332418142" target="_blank" className="af-class-link-4">Add your listing</a>
                        </div>
                      </div>
                      <div id="w-node-bfb85fcb-d563-f9f6-9e81-b3e57eb3b6d5-9ea8feb8" data-w-id="bfb85fcb-d563-f9f6-9e81-b3e57eb3b6d5" style={{opacity: 0}} className="af-class-paid-req af-class-new">
                        <div className="af-class-circle af-class-white">
                          <div className="af-class-text-block-5 af-class-white">2</div>
                        </div>
                        <div className="af-class-div-block-26">
                          <div>
                            <div className="af-class-text-block-6 af-class-white">Let the leads flow</div>
                            <div className="af-class-text-block-7 af-class-white">As soon as anyone shows interest in your unit we will let you know. It’s that easy sit back, relax and let the leads flow.</div>
                          </div>
                          <a href="https://www.jotform.com/form/213430332418142" target="_blank" className="af-class-link-4">Add your listing</a>
                        </div>
                      </div>
                    </div>
                    <a href="https://www.app.rentunicorn.com/create" className="af-class-hero-btn af-class-small af-class-mobile w-button">Unlock free leads</a>
                  </div>
                </div>
              </div>
              <div className="af-class-section-9 af-class-wf-section">
                <div className="af-class-div-block-30">
                  <div className="af-class-div-block-31">
                    <div className="af-class-background-video af-class-_45 w-embed">
                      <div style={{fontFamily: 'sans-serif', maxWidth: '100%'}}>
                        <div style={{}}>
                          <video autoPlay loop muted playsInline data-wf-ignore="true" data-object-fit="cover" width={800} height={800}>
                            <source src="https://unicorn-marketing-static-assets-us-east-1.s3.amazonaws.com/UnicornHeroVideoSmall.mov" type="video/mp4" />
                            <source src="https://unicorn-marketing-static-assets-us-east-1.s3.amazonaws.com/UnicornHeroVideo.webm" type="video/webm" />
                          </video>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-background-video af-class-_2 w-embed">
                      <div style={{fontFamily: 'sans-serif', maxWidth: '100%'}}>
                        <div style={{}}>
                          <video autoPlay loop muted playsInline data-wf-ignore="true" data-object-fit="cover" width={500} height={500}>
                            <source src="https://unicorn-marketing-static-assets-us-east-1.s3.amazonaws.com/UnicornHeroVideoSmall.mov" type="video/mp4" />
                            <source src="https://unicorn-marketing-static-assets-us-east-1.s3.amazonaws.com/UnicornHeroVideo.webm" type="video/webm" />
                          </video>
                        </div>
                      </div>
                    </div><img src="images/Transparent_iPhone_13Pro-1.svg" loading="lazy" alt className="af-class-image-22" />
                  </div>
                  <div className="af-class-div-block-32">
                    <div className="af-class-text-block-34">Unicorn is a video-first apartment listing platform.</div>
                  </div>
                </div>
              </div>
              <div className="af-class-section-7 af-class-wf-section">
                <div className="af-class-div-block-28">
                  <h2 className="af-class-heading-2 af-class-new af-class-_2">Add your rental, <br />get free leads.</h2>
                  <div>
                    <a href="https://www.jotform.com/form/213430332418142" target="_blank" className="af-class-hero-btn af-class-small w-button">List your rental</a>
                  </div>
                  <div className="af-class-div-block-35"><img src="images/Down-Chevron.svg" loading="lazy" data-w-id="6d09fe80-e17e-592d-8960-8bb17f1d9897" alt className="af-class-image-24" /></div>
                </div>
                <div className="af-class-div-block-29">
                  <div><img src="images/Rectangle-2258.png" loading="lazy" sizes="(max-width: 479px) 100vw, 50vw" srcSet="images/Rectangle-2258-p-500.png 500w, images/Rectangle-2258-p-800.png 800w, images/Rectangle-2258-p-1080.png 1080w, images/Rectangle-2258.png 1440w" alt className="af-class-image-21" /></div>
                </div>
              </div>
              <div className="af-class-section-8 af-class-wf-section">
                <div className="af-class-main-container">
                  <div className="af-class-cards-content">
                    <h2 className="af-class-heading">A lot of benefit for a little bit of time</h2>
                    <div className="af-class-cards-block">
                      <div data-w-id="94a6f9c4-8e31-bd91-17d9-8cbd9262ffcf" style={{opacity: 0}} className="af-class-cards"><img src="images/1c.svg" loading="lazy" alt />
                        <div className="af-class-text-block">Reduce Vacancy</div>
                        <div className="af-class-text-block-3">Unicorn is on a mission to eliminate rental vacancies for landlords. Our platform allows renters to plan ahead, finding properties before they ever hit the open market.</div>
                      </div>
                      <div id="w-node-_94a6f9c4-8e31-bd91-17d9-8cbd9262ffd5-9ea8feb8" data-w-id="94a6f9c4-8e31-bd91-17d9-8cbd9262ffd5" style={{opacity: 0}} className="af-class-cards"><img src="images/2c.svg" loading="lazy" alt />
                        <div className="af-class-text-block">Increase NOI</div>
                        <div className="af-class-text-block-3">Don’t wast money on listing fees, marketing campaigns or old school mailers. Let us help you find your next tenant, months before your current one leaves.</div>
                      </div>
                      <div id="w-node-_94a6f9c4-8e31-bd91-17d9-8cbd9262ffdb-9ea8feb8" data-w-id="94a6f9c4-8e31-bd91-17d9-8cbd9262ffdb" style={{opacity: 0}} className="af-class-cards"><img src="images/3c.svg" loading="lazy" alt />
                        <div className="af-class-text-block">Digitize Renting</div>
                        <div className="af-class-text-block-3">We are building a 100% digital experience, allowing both renters and landlords to manage everything in one place.</div>
                      </div>
                    </div>
                    <a data-w-id="94a6f9c4-8e31-bd91-17d9-8cbd9262ffe1" style={{opacity: 0}} href="https://www.jotform.com/form/213430332418142" target="_blank" className="af-class-sign-up af-class-nk w-button">Get free leads</a>
                  </div>
                </div>
              </div>
              <div className="af-class-section-10 af-class-wf-section" />
              <div className="af-class-section-11 af-class-wf-section">
                <div className="af-class-div-block-33">
                  <div className="af-class-flex-horizontal af-class-flip-svp">
                    <div className="af-class-flex-horizontal af-class-_2">
                      <div className="af-class-marquee">
                        <div className="af-class-marquee-vertical-css w-embed">
                          <style dangerouslySetInnerHTML={{__html: "\n.af-view .af-class-track-vertical {\n  position: absolute;\n  white-space: nowrap;\n  will-change: transform;\n  animation: marquee-vertical 70s linear infinite;\n  /* manipulate the speed of the marquee by changing \"20s\" line above*/\n}\n@keyframes marquee-vertical {\n  0% { transform: translateY(0); }\n  100% { transform: translateY(-50%); }\n}\n.af-view .af-class-track-vertical-alt{\n  position: absolute;\n  white-space: nowrap;\n  will-change: transform;\n  animation: marquee-vertical-alt 70s linear infinite;\n  /* manipulate the speed of the marquee by changing \"20s\" line above*/\n}\n@keyframes marquee-vertical-alt {\n  0% { transform: translateY(-50%); }\n  100% { transform: translateY(0%); }\n}\n" }} />
                        </div>
                        <div className="af-class-marquee-cover" />
                        <div className="af-class-track-vertical-alt">
                          <div className="af-class-flex-vertical af-class-marquee-fix">
                            <div className="af-class-icon-container"><img src="images/1w.svg" alt className="af-class-icon-4" /></div>
                            <div className="af-class-spacer-30 af-class-_15-xsvp" />
                            <div className="af-class-icon-container"><img src="images/2w.svg" alt className="af-class-icon-4" /></div>
                            <div className="af-class-spacer-30 af-class-_15-xsvp" />
                            <div className="af-class-icon-container"><img src="images/3w.svg" alt className="af-class-icon-4" /></div>
                            <div className="af-class-spacer-30 af-class-_15-xsvp" />
                            <div className="af-class-icon-container"><img src="images/4w.svg" alt className="af-class-icon-4" /></div>
                            <div className="af-class-spacer-30 af-class-_15-xsvp" />
                            <div className="af-class-icon-container"><img src="images/5w.svg" alt className="af-class-icon-4" /></div>
                            <div className="af-class-spacer-30 af-class-_15-xsvp" />
                            <div className="af-class-icon-container"><img src="images/6w.svg" alt className="af-class-icon-4" /></div>
                            <div className="af-class-spacer-30 af-class-_15-xsvp" />
                            <div className="af-class-icon-container"><img src="images/7w.svg" alt className="af-class-icon-4" /></div>
                            <div className="af-class-spacer-30 af-class-_15-xsvp" />
                            <div className="af-class-icon-container"><img src="images/8w.svg" alt className="af-class-icon-4" /></div>
                            <div className="af-class-spacer-30 af-class-_15-xsvp" />
                            <div className="af-class-icon-container"><img src="images/9w.svg" alt className="af-class-icon-4" /></div>
                            <div className="af-class-spacer-30 af-class-_15-xsvp" />
                            <div className="af-class-icon-container"><img src="images/10w.svg" alt className="af-class-icon-4" /></div>
                            <div className="af-class-spacer-30 af-class-_15-xsvp" />
                          </div>
                        </div>
                      </div>
                      <div className="af-class-marquee">
                        <div className="af-class-marquee-cover" />
                        <div className="af-class-track-vertical">
                          <div className="af-class-flex-vertical af-class-marquee-fix">
                            <div className="af-class-icon-container"><img src="images/1e.svg" alt className="af-class-icon-4" /></div>
                            <div className="af-class-spacer-30 af-class-_15-xsvp" />
                            <div className="af-class-icon-container"><img src="images/3e.svg" alt className="af-class-icon-4" /></div>
                            <div className="af-class-spacer-30 af-class-_15-xsvp" />
                            <div className="af-class-icon-container"><img src="images/4e.svg" alt className="af-class-icon-4" /></div>
                            <div className="af-class-spacer-30 af-class-_15-xsvp" />
                            <div className="af-class-icon-container"><img src="images/5e.svg" alt className="af-class-icon-4" /></div>
                            <div className="af-class-spacer-30 af-class-_15-xsvp" />
                            <div className="af-class-icon-container"><img src="images/6e.svg" alt className="af-class-icon-4" /></div>
                            <div className="af-class-spacer-30 af-class-_15-xsvp" />
                            <div className="af-class-icon-container"><img src="images/7e.svg" alt className="af-class-icon-4" /></div>
                            <div className="af-class-spacer-30 af-class-_15-xsvp" />
                            <div className="af-class-icon-container"><img src="images/8e.svg" alt className="af-class-icon-4" /></div>
                            <div className="af-class-spacer-30 af-class-_15-xsvp" />
                            <div className="af-class-icon-container"><img src="images/9e.svg" alt className="af-class-icon-4" /></div>
                            <div className="af-class-icon-container"><img src="images/cable_satellite_tv2.png" sizes="(max-width: 479px) 47vw, (max-width: 767px) 191.25px, (max-width: 1279px) 18vw, 191.25px" srcSet="images/cable_satellite_tv2-p-500.png 500w, images/cable_satellite_tv2-p-800.png 800w, images/cable_satellite_tv2-p-1080.png 1080w, images/cable_satellite_tv2-p-1600.png 1600w, images/cable_satellite_tv2.png 1782w" alt className="af-class-icon-4" /></div>
                            <div className="af-class-spacer-30 af-class-_15-xsvp" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-div-block-34">
                      <div data-w-id="178e31f5-febb-c564-91c2-53ddec01af70" style={{opacity: 0}} className="af-class-text-block-35">A modern approach to listing, built for leading landlords.</div>
                      <div className="af-class-div-block-39">
                        <a href="https://www.jotform.com/form/213430332418142" target="_blank" className="af-class-hero-btn af-class-small w-button">Join today</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-footer af-class-wf-section">
                <div className="af-class-main-container">
                  <div className="af-class-footer-content">
                    <div className="af-class-footer-grid">
                      <div id="w-node-_3f14daaa-32c1-3179-382a-dc85c0c9d02c-9ea8feb8" className="af-class-logo-block">
                        <a href="/" className="af-class-link-block-2 w-inline-block"><img src="images/Small-Logo.svg" loading="lazy" alt="Unicorn Logo" className="af-class-image-14" /></a>
                        <div className="af-class-text-block-15">Unicorn is a video-first apartment listing platform, powered by renters.</div>
                        <a href="sms:&body=Check%20out%20Unicorn%20🦄%20LA’s%20most%20exclusive%20apartment%20rental%20platform.%20https://www.rentunicorn.com/" className="af-class-invite-a-friend af-class-white w-button">Invite a friend</a>
                      </div>
                      <div id="w-node-_3f14daaa-32c1-3179-382a-dc85c0c9d033-9ea8feb8" className="af-class-footer-links">
                        <a href="https://rentunicorn.typeform.com/to/xYiZ2bos" className="af-class-links-item">About</a>
                        <a href="https://rentunicorn.typeform.com/to/xYiZ2bos" target="_blank" className="af-class-links-item af-class-white">Careers</a>
                        <a href="https://rentunicorn.typeform.com/to/W17RIkva" target="_blank" className="af-class-links-item af-class-white">Become a Scout</a>
                      </div>
                      <div id="w-node-_3f14daaa-32c1-3179-382a-dc85c0c9d03a-9ea8feb8" className="af-class-footer-links">
                        <a href="mailto:support@rentunicorn.com?subject=Unicorn%20Support" className="af-class-links-item">Support</a>
                        <a href="mailto:support@rentunicorn.com?subject=Unicorn%20Support" className="af-class-links-item af-class-white">Contact</a>
                        <a href="https://www.jotform.com/form/213430332418142" target="_blank" className="af-class-links-item af-class-white">Add a Listing</a>
                      </div>
                      <div id="w-node-_3f14daaa-32c1-3179-382a-dc85c0c9d041-9ea8feb8" className="af-class-footer-links">
                        <a href="mailto:support@rentunicorn.com?subject=Unicorn%20Support" className="af-class-links-item">Follow Us</a>
                        <div className="af-class-social-links">
                          <a href="https://www.instagram.com/rentunicorn/" target="_blank" className="af-class-social-icons w-inline-block"><img src="images/Subtract.svg" loading="lazy" alt="Instagram Link" /></a>
                          <a href="https://www.tiktok.com/@rentunicorn?" target="_blank" className="af-class-social-icons w-inline-block"><img src="images/Vector.svg" loading="lazy" alt="TikTok Link" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-footer-divider" />
                    <div className="af-class-post-footer-content">
                      <div className="af-class-copyright">
                        <div className="af-class-div-block-12">
                          <a href="#" className="w-inline-block"><img src="images/Group-2249_1.svg" loading="lazy" alt="Equal Housing" /></a>
                          <div className="af-class-text-block-17">Equal Housing<br />Opportunity</div>
                        </div>
                        <div className="af-class-text-block-19">Copyright © 2021. Unicorn. All rights reserved.</div>
                      </div>
                      <div id="w-node-_3f14daaa-32c1-3179-382a-dc85c0c9d055-9ea8feb8" className="af-class-terms-and-condition">
                        <a href="terms" className="af-class-link-2">Terms &amp; Conditions •</a>
                        <a href="privacy" className="af-class-link-2"> Privacy Policy</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default LandlordsView

/* eslint-enable */